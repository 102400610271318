import { TFunction } from 'i18next';
import { omitFromObject } from '@utils/objects';
import { FiltersObjectInterface } from '@type/form-types';

/**
 * Generates the meta description for the job listing page.
 *
 * @param {TFunction} t - The translation function.
 * @param {FiltersObjectInterface | undefined} filters - The object containing the search filters labels.
 * @param {number} [totalJobs] - The total number of jobs.
 * @returns {string} - The translated meta description.
 */

export const getJobListMetaDescription = (t: TFunction, filters: FiltersObjectInterface | undefined, totalJobs: number) => {
  // Initialize langKey
  // *****************************************************
  let langKey: string = 'react.jobs.list.description';

  // Build the translation key
  // *****************************************************
  // Add domain
  if (filters?.domain) langKey += '.domain';

  // Add location
  if (filters?.location) langKey += '.location';

  // Handle remaining filters
  // *****************************************************
  // Exclude `domain`, `location`, `employmentTypes`, and `sort`
  const remainingFilters = filters ? omitFromObject<FiltersObjectInterface, keyof FiltersObjectInterface>(
    filters,
    ['domain', 'location', 'employmentTypes', 'sort'],
  ) : {};

  // If there are no remaining filters, append `.no.filter`
  if (Object.keys(remainingFilters).length === 0) {
    langKey += '.no.filter';
  }

  // Build the label for the other filters
  // *****************************************************
  const restFilters = [
    filters?.careerLevels,
    filters?.spokenLanguages,
    filters?.remote ? ['global.job.employment_type.remote'] : undefined,
    filters?.benefits,
  ]
    .filter((labels) => labels && labels.length)
    .flat()
    .map((label) => label && t(label))
    .join(', ');

  // Return the translated key
  // *****************************************************
  return t(langKey, {
    total: totalJobs,
    employmentType: filters?.employmentTypes ? ` ${filters?.employmentTypes.map((label) => t(label)).join(', ')}` : '',
    location: filters?.location?.map((label) => t(label)).join(', '),
    domain: filters?.domain?.map((label) => t(label)).join(', '),
    restFilters,
  });
};
