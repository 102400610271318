// Job List Type
// *************************************************************************************************
export enum JobListType {
  // similar jobs on job detail page
  JOB_DETAIL_SIMILAR_LIST = '5',

  // similar jobs after job detail, if visitor clicks see all similar jobs
  SIMILAR_LIST = '6',

  // favorite jobs list
  FAVORITE_LIST = '22',

  // job search
  SEARCH_LIST_APISEARCH = '26',

  // blocked jobs list
  BLOCKED_LIST = '27',
}

// Job Placement
// *************************************************************************************************
export enum JobPlacement {
  CARD = 'from_list',
  DETAIL = 'job_detail',
  FEED = 'feed',
  COMPANY_DETAIL = 'company_detail',
  INTERNAL = 'internal',
  USER_MESSAGE = 'user_message',
}

// Job Query Params
// *************************************************************************************************
export enum JobQueryParams {
  SEARCH_ID = 'sid',
  POSITION = 'pos',
  LIST_TYPE = 'list',
  TR_REQUEST_ID = 'rid',
  PLACEMENT = 'placement',
}
