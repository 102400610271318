import { TFunction } from 'i18next';
import { ApiJobDetailOk } from '@type/v1-api-types';

export const getJobMetaDescription = (job: ApiJobDetailOk | null, t: TFunction) => {
  if (!job) {
    return t('meta.homepage.description_react');
  }

  const metaDescriptionKey = job.remote ? 'job.detail.meta.description.remote.react' : 'job.detail.meta.description.react';
  return t(metaDescriptionKey, {
    employer: job.employer?.employerName,
    jobTitle: job.title,
    location: (job.locations?.length ? job.locations.map((location) => location.name).join(', ') : '') + (job.partialRemote ? `, ${t('meta.label.partial.remote')}` : ''),
  });
};
