import { TFunction } from 'i18next';
import { JobSearchQueryParams } from '@type/filters';
import { SEARCH_FORM_FILTERS_OBJECT } from '@components/features/filters/helpers/searchFiltersObject';
import { getI18nLinkHref } from '@services/i18nPaths';
import { Locale } from 'i18n-config';
import { slugify } from '@utils/strings/slugify';

/**
 * Generates the job list SEO URL based on the provided filter parameters.
 * The order is important: domains, employmentTypes, careerLevels, benefits, spokenLanguages, keyword, remote, sort.
 *
 * @param {TFunction} t - The translation function used to localize certain URL parameters.
 * @param {Locale} locale - The current locale of the app.
 * @param {JobSearchQueryParams | undefined} objFilterParams - The object containing the search filters.
 * @param {number | undefined} pageNr - The current page number.
 *
 * * @returns {string | undefined} The SEO url or undefined if no filters are provided.
 */
export const buildJobListUrl = (
  t: TFunction,
  locale: Locale,
  objFilterParams: JobSearchQueryParams | undefined,
  pageNr?: number,
): string | undefined => {
  const urlParts: string[] = [];

  if (!objFilterParams) {
    return undefined;
  }

  const addFilterSlugs = (filterArray: (string | number)[] | undefined, filterType: keyof typeof SEARCH_FORM_FILTERS_OBJECT) => {
    if (filterArray?.length) {
      const filterObjectFields = SEARCH_FORM_FILTERS_OBJECT[filterType].fields;

      const slugs = filterArray
        .map((item) => {
          const fieldItem = filterObjectFields.find((field) => field.defaultValue === item);
          return fieldItem?.slug || (fieldItem?.filtersLabel && slugify(t(fieldItem.filtersLabel)));
        })
        .filter(Boolean);

      if (slugs.length) {
        urlParts.push(slugs.join(','));
      }
    }
  };

  addFilterSlugs(objFilterParams.domain, 'domains');
  addFilterSlugs(objFilterParams.employmentTypes, 'employmentTypes');
  addFilterSlugs(objFilterParams.careerLevels, 'careerLevels');

  if (objFilterParams.benefits?.length) {
    urlParts.push(t('url.param.benefits'));
    addFilterSlugs(objFilterParams?.benefits, 'benefits');
  }

  addFilterSlugs(objFilterParams.spokenLanguages, 'spokenLanguages');

  if (objFilterParams.keyword) {
    urlParts.push(slugify(objFilterParams.keyword));
  }

  if (objFilterParams.remote) {
    urlParts.push(t('remote'));
  }

  if (objFilterParams.sort) {
    urlParts.push(objFilterParams.sort);
  }

  if (pageNr && pageNr > 1) {
    urlParts.push(pageNr.toString());
  }

  const finalUrlPart = urlParts.length ? `/${urlParts.join('/')}` : '';

  if (objFilterParams.location) {
    const locationSlug = Array.isArray(objFilterParams.location)
      ? slugify(objFilterParams.location.join(','))
      : slugify(objFilterParams.location);

    // Get i18nLink
    const i18nLink = getI18nLinkHref(`/job/list/location-${locationSlug}`, locale);

    // The location slug is URL encoded, so we need to replace `%2C` with `comma`
    return `${i18nLink.replace(/%2C/g, ',')}${finalUrlPart}`;
  }

  return `${getI18nLinkHref('/job/list', locale)}${finalUrlPart}`;
};
