import { JobQueryParams } from '@type/job-params';

// Props interface.
// **************************************
interface BuildJobDetailUrlParamsInterface {
  requestId?: string;
  searchId?: string;
  position?: number | string;
  listType?: string;
}

/**
 * @description This method generates the URL parameters for the job detail pages.
 * It is used to ensure that each job detail URL contains the necessary information for analytics and user navigation.
 *
 * Return:
 * - **urlParams** - URLSearchParams - The URL parameters for the job detail page.
 *
 * @example
 * const urlSearchParams = buildJobDetailUrlParams({
 *  requestId: similarJobs.apiRequestId,
 *  position: typeof query[JobQueryParams.POSITION] === 'string' ? query[JobQueryParams.POSITION] : undefined,
 *  listType: JobListType.JOB_DETAIL_SIMILAR_LIST,
 * });
 */
export const buildJobDetailUrlParams = (props: BuildJobDetailUrlParamsInterface) : URLSearchParams => {
  // Destructure props
  const {
    requestId,
    searchId,
    position,
    listType,
  } = props;

  const searchParams = new URLSearchParams();

  // Append Tracking query param
  if (requestId) {
    searchParams.append(JobQueryParams.TR_REQUEST_ID, requestId);
  }

  // Append position query param
  if (position?.toString()) {
    searchParams.append(JobQueryParams.POSITION, position?.toString());
  }

  // Append Search ID query param
  if (searchId) {
    searchParams.append(JobQueryParams.SEARCH_ID, searchId);
  }

  // Append list type param
  if (listType) {
    searchParams.append(JobQueryParams.LIST_TYPE, listType);
  }

  return searchParams;
};
