import { TFunction } from 'i18next';
import { ApiJobDetailOk } from '@type/v1-api-types';

export const getJobTitle = (job: ApiJobDetailOk | null, t: TFunction) => {
  if (!job) {
    return t('homepage.react.meta.title');
  }

  let title = `${job.title} - ${job.locations?.length ? job.locations.slice(0, 5).map((location) => location.name).join(', ') : t('job.add.label.remote')}`;
  title += `${job.employer ? `- ${(job.employer.confidential ? (`${t('employer.name.confidential')} ${t('global.label.company')}`) : job.employer.employerName)}` : ''}`;
  title += ' - BestJobs.eu';

  return title;
};
