type IGlobalSearchFiltersKeys = 'sortBy' | 'employmentTypes' | 'careerLevels' | 'benefits' | 'domains' | 'spokenLanguages' | 'remote';
type IGlobalSearchFiltersObject = { [K in IGlobalSearchFiltersKeys]: ISearchFiltersObject } & { [k: string]: ISearchFiltersObject };

export interface ISearchFiltersObject {
  apiParameter: string;
  label: string;
  order: number;
  valueAsArray?: boolean,
  fields: {
    filtersLabel: string; // translation key
    defaultValue: string | number; // field value
    slug?: string; // translation key - the string that is added in URL
  }[]
}

export const SEARCH_FORM_FILTERS_OBJECT: IGlobalSearchFiltersObject = {
  sortBy: {
    apiParameter: 'sort',
    label: 'global.label.order-by',
    order: 1,
    fields: [
      {
        filtersLabel: 'order-by-relevance',
        defaultValue: '',
        slug: '',
      },
      {
        filtersLabel: 'order-by-salary',
        defaultValue: 'salary',
        slug: 'salary',
      },
      {
        filtersLabel: 'order-by-recency',
        defaultValue: 'recent',
        slug: 'recent',
      },
      {
        filtersLabel: 'order-by-distance',
        defaultValue: 'distance',
        slug: 'distance',
      },
    ],
  },
  employmentTypes: {
    apiParameter: 'employmentTypes',
    label: 'job_search.filter.work_preferences',
    order: 2,
    valueAsArray: true,
    fields: [
      {
        filtersLabel: 'global.job.employment_type.fulltime',
        defaultValue: '1',
      },
      {
        filtersLabel: 'global.job.employment_type.parttime',
        defaultValue: '2',
      },
      {
        filtersLabel: 'global.job.employment_type.internship',
        defaultValue: '4',
      },
      {
        filtersLabel: 'global.job.employment_type.project',
        defaultValue: '5',
      },
      {
        filtersLabel: 'global.job.employment_type.voluntarily',
        defaultValue: '6',
      },
    ],
  },
  careerLevels: {
    apiParameter: 'careerLevels',
    label: 'global.filter.experience-label',
    order: 3,
    valueAsArray: true,
    fields: [
      {
        filtersLabel: 'global.filter.experience.none',
        defaultValue: '0_0',
      },
      {
        filtersLabel: 'global.filter.experience.entry',
        defaultValue: '_2',
      },
      {
        filtersLabel: 'global.filter.experience.middle',
        defaultValue: '2_5',
      },
      {
        filtersLabel: 'global.filter.experience.senior',
        defaultValue: '5_10',
      },
      {
        filtersLabel: 'global.filter.experience.executive',
        defaultValue: '10_',
      },
    ],
  },
  benefits: {
    apiParameter: 'benefits',
    label: 'global.filter.benefits',
    order: 4,
    valueAsArray: true,
    fields: [
      {
        defaultValue: '1',
        filtersLabel: 'benefit.group.financial.item1',
      },
      {
        defaultValue: '2',
        filtersLabel: 'benefit.group.financial.item2',
      },
      {
        defaultValue: '3',
        filtersLabel: 'benefit.group.financial.item3',
      },
      {
        defaultValue: '4',
        filtersLabel: 'benefit.group.financial.item4',
      },
      {
        defaultValue: '5',
        filtersLabel: 'benefit.group.financial.item5',
      },
      {
        defaultValue: '6',
        filtersLabel: 'benefit.group.financial.item6',
      },
      {
        defaultValue: '7',
        filtersLabel: 'benefit.group.financial.item7',
      },
      {
        defaultValue: '8',
        filtersLabel: 'benefit.group.financial.item8',
      },
      {
        defaultValue: '9',
        filtersLabel: 'benefit.group.financial.item9',
      },
      {
        defaultValue: '10',
        filtersLabel: 'benefit.group.medical.item1',
      },
      {
        defaultValue: '11',
        filtersLabel: 'benefit.group.medical.item2',
      },
      {
        defaultValue: '12',
        filtersLabel: 'benefit.group.development.item1',
      },
      {
        defaultValue: '13',
        filtersLabel: 'benefit.group.development.item2',
      },
      {
        defaultValue: '14',
        filtersLabel: 'benefit.group.development.item3',
      },
      {
        defaultValue: '15',
        filtersLabel: 'benefit.group.development.item4',
      },
      {
        defaultValue: '16',
        filtersLabel: 'benefit.group.workplace.item1',
      },
      {
        defaultValue: '17',
        filtersLabel: 'benefit.group.workplace.item2',
      },
      {
        defaultValue: '18',
        filtersLabel: 'benefit.group.workplace.item3',
      },
      {
        defaultValue: '19',
        filtersLabel: 'benefit.group.workplace.item4',
      },
      {
        defaultValue: '20',
        filtersLabel: 'benefit.group.workplace.item5',
      },
      {
        defaultValue: '21',
        filtersLabel: 'benefit.group.workplace.item6',
      },
      {
        defaultValue: '22',
        filtersLabel: 'benefit.group.workplace.item7',
      },
      {
        defaultValue: '23',
        filtersLabel: 'benefit.group.workplace.item8',
      },
      {
        defaultValue: '24',
        filtersLabel: 'benefit.group.workplace.item9',
      },
      {
        defaultValue: '25',
        filtersLabel: 'benefit.group.free_time.item1',
      },
      {
        defaultValue: '26',
        filtersLabel: 'benefit.group.free_time.item2',
      },
      {
        defaultValue: '27',
        filtersLabel: 'benefit.group.free_time.item3',
      },
    ],
  },
  domains: {
    apiParameter: 'domain',
    label: 'global.filter.domains',
    order: 1,
    valueAsArray: true,
    fields: [
      {
        defaultValue: '8',
        filtersLabel: 'domain.administration',
      },
      {
        defaultValue: '16',
        filtersLabel: 'domain.agriculture',
      },
      {
        defaultValue: '17',
        filtersLabel: 'domain.security',
      },
      {
        defaultValue: '12',
        filtersLabel: 'domain.babysitter.clean',
      },
      {
        defaultValue: '6',
        filtersLabel: 'domain.callcenter',
      },
      {
        defaultValue: '4',
        filtersLabel: 'domain.construction',
      },
      {
        defaultValue: '7',
        filtersLabel: 'domain.finance',
      },
      {
        defaultValue: '13',
        filtersLabel: 'domain.education',
      },
      {
        defaultValue: '14',
        filtersLabel: 'domain.engineering',
      },
      {
        defaultValue: '9',
        filtersLabel: 'domain.it',
      },
      {
        defaultValue: '20',
        filtersLabel: 'domain.legislators',
      },
      {
        defaultValue: '19',
        filtersLabel: 'domain.management',
      },
      {
        defaultValue: '10',
        filtersLabel: 'domain.marketing',
      },
      {
        defaultValue: '15',
        filtersLabel: 'domain.medicine',
      },
      {
        defaultValue: '5',
        filtersLabel: 'domain.production',
      },
      {
        defaultValue: '18',
        filtersLabel: 'domain.hr',
      },
      {
        defaultValue: '70',
        filtersLabel: 'domain.retail',
      },
      {
        defaultValue: '11',
        filtersLabel: 'domain.beauty',
      },
      {
        defaultValue: '2',
        filtersLabel: 'domain.transportation',
      },
      {
        defaultValue: '21',
        filtersLabel: 'domain.technician',
      },
      {
        defaultValue: '24',
        filtersLabel: 'domain.sportart',
      },
      {
        defaultValue: '3',
        filtersLabel: 'domain.horeca',
      },
      {
        defaultValue: '1',
        filtersLabel: 'domain.sales',
      },
      {
        defaultValue: '23',
        filtersLabel: 'domain.others',
      },
    ],
  },
  spokenLanguages: {
    apiParameter: 'spokenLanguages',
    label: 'global.filter.spoken_lang',
    order: 5,
    valueAsArray: true,
    fields: [
      {
        defaultValue: 'ar',
        filtersLabel: 'Arabă',
      },
      {
        defaultValue: 'bg',
        filtersLabel: 'Bulgară',
      },
      {
        defaultValue: 'cs',
        filtersLabel: 'Cehă',
      },
      {
        defaultValue: 'da',
        filtersLabel: 'Daneză',
      },
      {
        defaultValue: 'de',
        filtersLabel: 'Germană',
      },
      {
        defaultValue: 'el',
        filtersLabel: 'Greacă',
      },
      {
        defaultValue: 'en',
        filtersLabel: 'Engleză',
      },
      {
        defaultValue: 'es',
        filtersLabel: 'Spaniolă',
      },
      {
        defaultValue: 'fi',
        filtersLabel: 'Finlandeză',
      },
      {
        defaultValue: 'fr',
        filtersLabel: 'Franceză',
      },
      {
        defaultValue: 'he',
        filtersLabel: 'Ebraică',
      },
      {
        defaultValue: 'hr',
        filtersLabel: 'Croată',
      },
      {
        defaultValue: 'hu',
        filtersLabel: 'Maghiară',
      },
      {
        defaultValue: 'it',
        filtersLabel: 'Italiană',
      },
      {
        defaultValue: 'ja',
        filtersLabel: 'Japoneză',
      },
      {
        defaultValue: 'ko',
        filtersLabel: 'Coreeană',
      },
      {
        defaultValue: 'lv',
        filtersLabel: 'Letonă',
      },
      {
        defaultValue: 'nl',
        filtersLabel: 'Neerlandeză',
      },
      {
        defaultValue: 'no',
        filtersLabel: 'Norvegiană',
      },
      {
        defaultValue: 'pl',
        filtersLabel: 'Poloneză',
      },
      {
        defaultValue: 'pt',
        filtersLabel: 'Portugheză',
      },
      {
        defaultValue: 'ro',
        filtersLabel: 'Română',
      },
      {
        defaultValue: 'ru',
        filtersLabel: 'Rusă',
      },
      {
        defaultValue: 'sk',
        filtersLabel: 'Slovacă',
      },
      {
        defaultValue: 'sl',
        filtersLabel: 'Slovenă',
      },
      {
        defaultValue: 'sr',
        filtersLabel: 'Sârbă',
      },
      {
        defaultValue: 'sv',
        filtersLabel: 'Suedeză',
        slug: 'suedeza',
      },
      {
        defaultValue: 'tr',
        filtersLabel: 'Turcă',
        slug: 'turca',
      },
      {
        defaultValue: 'uk',
        filtersLabel: 'Ucraineană',
        slug: 'ucraineana',
      },
      {
        defaultValue: 'zh',
        filtersLabel: 'Chineză',
        slug: 'chineza',
      },
    ],
  },
  remote: {
    label: 'header.filter.remote',
    apiParameter: 'remote',
    order: 10,
    fields: [
      {
        filtersLabel: 'header.filter.remote',
        defaultValue: 1,
        slug: 'remote',
      },
    ],
  },
};
