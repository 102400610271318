/**
 * @description Convert a string to camelCase
 * @param text
 *
 * @example
 * toCamelCase('some_text') // => 'someText'
 * toCamelCase('_some_text') // => 'someText'
 * toCamelCase('some-text') // => 'someText'
 * toCamelCase('-some_other-text') // => 'someOtherText'
 */
export const toCamelCase = (text: string) => text
  ?.trim().replace(/^[-_\s]+|[-_\s]+$/g, '') // remove leading and trailing hyphens, underscores, and spaces
  .toLowerCase() // convert to lowercase, so that uppercase letters are not treated as word boundaries
  .replace(/([-_\s][a-z])/g, (group) => group
    .toUpperCase()
    .replace('-', '')
    .replace('_', '')
    .replace(' ', ''));

/**
 * @description Converts a given text string to a specified separator case.
 * @param text - The text to convert.
 * @param options - The options object.
 * @param options.separator - The character to use as the separator. Default '-'.
 * @param options.decamelize - Whether to convert camelCase to separator case. Default true.
 *
 * @example
 * toSeparatorCase('someText') // => 'some-text'
 * toSeparatorCase('someText', { separator: '+' } ) // => 'some+text'
 * toSeparatorCase('someText', { separator: '+', decamelize: false } ) // => 'sometext'
 * toSeparatorCase('snake_case_string') // => 'snake-case-string'
 * toSeparatorCase('snake_case_string', { separator: '+' }) // => 'snake+case+string'
 * toSeparatorCase('kebab-case-string', { separator: '+' }) // => 'kebab+case+string'
 * toSeparatorCase('CamelCase And snake_case') // => 'camel-case-and-snake-case'
 * toSeparatorCase('CamelCase And snake_case', {separator: '+'}) // => 'camel+case+and+snake+case'
 * toSeparatorCase('CamelCase And snake_case', {decamelize: false}) // => 'camelcase-and-snake-case'
 */
export const toSeparatorCase = (text: string, options?: { separator?: string, decamelize?: boolean }) => {
  // Destructure options with default values
  const { separator = '-', decamelize = true } = options || {};

  if (!text) return ''; // Handle null or undefined text

  // Initial cleanup and normalization
  let convertedText = text.trim()
    .replace(/^[-_\s]+|[-_\s]+$/g, '') // remove leading and trailing hyphens, underscores, and spaces
    .normalize('NFD') // Normalize the text to decomposed form
    .replace(/[\u0300-\u036f]/g, ''); // Remove diacritical marks;

  // Decamelize if required
  if (decamelize) {
    convertedText = convertedText.replace(/([a-z])([A-Z])/g, `$1${separator}$2`); // convert camelCase
  }

  return convertedText
    .replace(/[_\s]/g, separator) // convert snake_case
    .replace(/[-\s]/g, separator) // convert separator_case
    .toLowerCase();
};

/**
 * @description Convert a string to snake_case
 * @param text
 *
 * @example
 * toSnakeCase('someText') // => 'some_text'
 * toSnakeCase('SomeText') // => 'some_text'
 * toSnakeCase('kebab-case-string') // => 'kebab_case_string'
 * toSnakeCase('CamelCase And Kebab-Case') // => 'camel_case_and_kebab_case'
 */
export const toSnakeCase = (text: string) => toSeparatorCase(text, { separator: '_' });


/**
 * @description Convert a string to kebab-case
 * @param text
 *
 * @example
 * toKebabCase('someText') // => 'some-text'
 * toKebabCase('SomeText') // => 'some-text'
 * toKebabCase('snake_case_string') // => 'snake-case-string'
 * toKebabCase('CamelCase And snake_case') // => 'camel-case-and-snake-case'
 */
export const toKebabCase = (text: string) => toSeparatorCase(text, { separator: '-' });
