import { SEARCH_FORM_FILTERS_OBJECT } from '@components/features/filters/helpers/searchFiltersObject';
import { JobSearchQueryParams } from '@type/filters';
import { slugify } from '@utils/strings/slugify';
import { TFunction } from 'i18next';

/**
 * Parses the provided slug from Next Router Query to generate job search query parameters.
 *
 * @param {string | string[] | undefined} slug - The slug representing job filter parameters.
 * @param {TFunction} t - The translation function used to localize certain URL parameters.
 * @returns {JobSearchQueryParams} An object representing the job search query parameters derived from the slug.
 */
export const getJobListParametersFromSlug = (slug: string | string[] | undefined, t?: TFunction): JobSearchQueryParams => {
  const filtersEntries = Object.entries(SEARCH_FORM_FILTERS_OBJECT);
  const forceStringValues = ['sort'];
  const filterParams: { [key: string]: string | string[] } = {
    // sort: '',
    // careerLevels: [],
    // employmentTypes: [],
    // domain: [],
    // remote: '',
    // benefits: [],
    // spokenLanguages: [],
    keyword: '',
    // location: '',
  };

  if (Array.isArray(slug)) {
    slug.forEach((slugItem) => {
      if (t && slugItem === t('url.param.benefits')) {
        return;
      }

      // If item is a location, it will have 'location-' prefix
      if (slugItem.startsWith('location-')) {
        filterParams.location = slugItem
          .replace('location-', '')
          .split(',');
      } else {
        const items = slugItem.split(',');
        items.forEach((item) => {
          let itemFound = false;

          filtersEntries.every((entry) => {
            const filterData = entry[1];
            const index = filterData.fields
              .findIndex((field) => field.slug === item
                || (!field.slug && field.filtersLabel && t && slugify(t(field.filtersLabel)) === item));

            if (index > -1) {
              if (!Array.isArray(filterParams[filterData.apiParameter])) {
                const filterValue = filterData.fields[index].defaultValue as string;
                filterParams[filterData.apiParameter] = forceStringValues.includes(filterData.apiParameter) ? filterValue : [filterValue];
              } else {
                (filterParams[filterData.apiParameter] as unknown[]).push(filterData.fields[index].defaultValue);
              }

              itemFound = true;
            }

            // With every(), return false is equivalent to a break, and return true is equivalent to continue.
            return index === -1;
          });

          // If item is not found in filters, then it can be a keyword.
          // But only the first one is considered a keyword.
          if (!itemFound && !filterParams.keyword) {
            filterParams.keyword = item;
          }
        });
      }
    });
  }

  return filterParams;
};
