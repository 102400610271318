import { toSeparatorCase } from '@utils/strings/convertCase';

/**
 * @description Converts a text to a slug
 * @example
 *
 * slugify("Programator de php") -> programator-de-php
 *
 * @param text
 */
export const slugify = (text?: string): string => {
  if (!text) {
    return '';
  }
  return toSeparatorCase(text, { separator: '-', decamelize: true })
    .replace(/[()<>]/g, '') // Remove parentheses and '<' and '>'
    .replace(/[&/]/g, '-') // Replace ampersands and slashes with hyphens
    .replace(/-+/g, '-') // Replace multiple hyphens with a single one
    .replace(/[\u200B-\u200D\uFEFF]/g, ''); // Remove zero-width spaces and other invisible characters
};
