import { TFunction } from 'i18next';
import { FiltersObjectInterface } from '@type/form-types';

/**
 * Generates the title for the job listing page.
 *
 * @param {TFunction} t - The translation function.
 * @param {FiltersObjectInterface | undefined} filters - The object containing the search filters labels.
 * @param {number} totalJobs - The total number of jobs.
 * @returns {string} - The translated title.
 */
export const getJobListTitle = (t: TFunction, filters: FiltersObjectInterface | undefined, totalJobs: number) => {
  // Initialize langKey
  // *****************************************************
  let translationKey = 'react.job.list.title';

  // Build the translation key
  // *****************************************************
  // Add domain
  if (filters?.domain) translationKey += '.domain';

  // Add location
  if (filters?.location) translationKey += '.location';

  // Add keyword
  if (filters?.keyword) translationKey += '.keyword';

  // Build the label for domains
  // *****************************************************
  const domains = filters?.domain?.map((label) => t(label)).join(', ');

  // Build the label for the other filters
  // *****************************************************
  const restFilters = [
    filters?.employmentTypes,
    filters?.careerLevels,
    filters?.spokenLanguages,
    filters?.remote ? ['global.job.employment_type.remote'] : undefined,
    filters?.benefits,
  ]
    .filter((labels) => labels && labels.length)
    .flat()
    .map((label) => label && t(label))
    .join(', ');

  // Return the translated key
  // *****************************************************
  return t(translationKey, {
    keyword: filters?.keyword ? filters?.keyword.join(', ') : undefined,
    domain: domains,
    location: filters?.location,
    total: totalJobs,
    filters: restFilters,
  });
};
